.actionRow, .resourceRow {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.resourceRow {
    background-color: #f1f2f2;
    margin-top: 15px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.actionRow {
    border-top: 1px solid #787777;
}

.permissionSetterContainer {
    border-radius: 7px;
}
.actionCol {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.DateInput_input {
    width: 100%;
}
#createEmployeeDatePicker .DateInput {
    width: 80%;
}
.DateInput {
    display: inline-flex;
}
.SingleDatePickerInput {
    background-color: #f1f2f2;
    border-radius: 7px;
    padding: 0.305rem 1rem 0.625rem;
    width: 100%;
} 

.paragraph-en {
    text-align: left;
}

.paragrapgh-ar {
    text-align: right;
}