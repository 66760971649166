@import "../../styles/vars";
@import "../../styles/mixins/type";

.form__row {
  position: relative;
}
.form__row + .form__row,
.form__cols + .form__row,
.form__row + .form__cols {
  margin-top: 16px;
}
.form__row + .form__row_submit,
.form__cols + .form__row_submit,
.form__row + .form__row_offset,
.form__cols + .form__row_offset {
  margin-top: 32px;
  display: flex;
}
.form__row + .form__row_extra,
.form__cols + .form__row_extra {
  margin-top: 40px;
}
.form__label {
  color: $color-black;
  font-size: 16px;
  line-height: 1.125;
  display: block;
  margin-bottom: .5em;
}
.form__hint,
.form__error-en {
  font-size: 13px;
  margin-top: .5rem;
  padding-left: .25rem;
  padding-right: .25rem;
}

.form__error-ar {
  font-size: 13px;
  margin-top: .5rem;
  padding-left: .25rem;
  padding-right: .25rem;
  text-align: right;
}
.form__error-en, .form__error-ar {
  color: $color-red;
}
.form__hint {
  color: $color-black40;

  a {
    color: $color-black40;

    &:hover {
      color: $color-black;
    }
  }
}

.row.form__cols {
  margin-left: -8px;
  margin-right: -8px;

  & > .form__col {
    padding-left: 8px;
    padding-right: 8px;
  }
}
