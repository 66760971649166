@import "../../styles/vars";
@import "../../styles/mixins/type";

.message {
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 1100;
  width: 100%;
  pointer-events: none;

  &-notice {
    padding: 8px;
    text-align: center;
    &:first-child {
      margin-top: -8px;
    }
  }

  &-notice-content {
    font-size: 13px;
    display: inline-block;
    padding: 1rem 1.5rem;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $elevate-4;
    pointer-events: all;
  }

  &-success .icon {
    color: $color-green;
  }

  &-error .icon {
    color: $color-red;
  }

  &-warning .icon {
    color: $color-red;
  }

  &-info .icon,
  &-loading .icon {
    color: $color-green;
  }

  .icon {
    margin-right: 0.5rem;
  }

  &-notice.move-up-leave.move-up-leave-active {
    animation-name: MessageMoveOut;
    animation-duration: 0.3s;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
