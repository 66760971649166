@import "../../styles/vars";
@import "../../styles/mixins/type";

.input {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  cursor: text;
  direction: ltr;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-black95;
    border: 2px solid $color-black95;
    border-radius: $border-radius;
    will-change: border-color, background-color;
    transition: border-color 100ms, background-color 100ms;
  }

  &:hover {
    &:before {
      border-color: $color-black90;
      background-color: $color-black90;
    }
  }
}
%input__input {
  font-size: 16px;
  position: relative;
  display: block;
  width: 100%;
  margin: -2px 0 0;
  padding: 1.875rem 1rem 0.625rem;
  background: transparent;
  border: 0;
  border-radius: $border-radius;
  opacity: 0;
  transition: opacity 300ms;
  will-change: opacity;


  &:focus {
    outline: none;
  }
}

.input__input-en {
  @extend %input__input;
  text-align: 'right';
}

.input__input-ar {
  @extend %input__input;
  text-align: right;
}

%input__label {
  color: $color-black64;
  font-size: 16px;
  position: absolute;
  top: 50%;
  user-select: none;
  transform: translate3d(0, -50%, 0) scale(1);
  transition: transform 300ms;
  will-change: transform;
}

.input__label-en {
  @extend %input__label;
  left: 1rem;
}

.input__label-ar {
  @extend %input__label;
  right: 1rem;
}

.input_focused {
  &,
  &:hover {
    &:before {
      border-color: $color-violet-hover;
      background-color: $color-black98;
    }
  }
}
.input_focused,
.input_filled {
  .input__label-en {
    transform: translate3d(-12.5%, -1.566rem, 0) scale(0.75);
    // transform: translate3d(0, -1.566rem, -12.5%) scale(0.75);
  }
  .input__label-ar {
    transform: translate3d(-12.5%, -1.566rem, 0) scale(0.75);
    // transform: translate3d(0, -1.566rem, -12.5%) scale(0.75);
  }
  .input__input-ar,
  .input__input-en {
    opacity: 1;
  }
}

// Input with error
.input_error {
  &,
  &:hover {
  //&.input__focused,
  //&.input__focused:hover {
    &:before {
      border-color: $color-red;
    }
  }
}

// Disabled
.input_disabled {
  &,
  &:hover {
    &:before {
      background: $color-black90;
      border-color: $color-black90;
    }
  }
  .input__input-ar,
  .input__input-en {
    color: $color-black40;
  }
  .input__placeholder {
    color: $color-black64;
  }
}

// No label
.input_no-label {
  .input__input-ar,
  .input__input-en {
    padding: 1.25rem 1rem 1.25rem;
  }
}
