@import "../../styles/vars";
@import "../../styles/mixins/type";
@import '../../styles/mixins/breakpoints';

.dashboard-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 112px;
  margin: 0 0 40px;

  &__title {
    font-size: 28px;
    font-weight: bold;
    white-space: nowrap;
    margin: 0;
    padding: 0 24px 0 0;
  }
  &__nav {
    flex-grow: 1;
  }
  &__user {
    padding: 0 0 0 16px;
  }

  @include media-breakpoint-down(lg) {
    & {
      display: block;
      height: auto;
      margin: 32px 0 24px;
    }
    &__title {
      margin-bottom: 8px;
    }
    &__user {
      display: none;
    }
  }
}

.dashboard-header__nav-ar {
  text-align: right;
}