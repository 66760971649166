@import "../../styles/vars";
@import "../../styles/mixins/type";

.otp-control {
  text-align: center;
  margin: 1.5rem auto;

  p {
    margin: 0 0 .5rem;
  }
  &__actions {
    font-size: 13px;
  }
}
