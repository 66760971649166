@import "../../styles/vars";
@import "../../styles/mixins/type";

.integration-option {
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  border: 1px solid $color-black90;
  border-radius: $border-radius-large;

  &__logo-en {
    margin-right: 1.5rem;

    svg {
      width: 2rem;
    }
  }

  &__logo-ar {
    margin-left: 1.5rem;

    svg {
      width: 2rem;
    }
  }
  &__content {
    flex-grow: 1;
  }

  &__title {
    display: flex;
    font-weight: bold;
    margin: 0;

  }
  %__description {
    display: flex;
    font-size: 13px;
    margin: 0.25rem 0 0;
  }

  &__description-en {
    @extend %__description;
    text-align: left;
  }

  &__description-ar {
    @extend %__description;
    text-align: right;
  }
  &__actions {
    display: flex;
    margin-top: 1rem;
  }
}
