.SingleDatePicker {
    width: 100%;
}
.DateInput_input {
    width: 100%;
}
#createEmployeeDatePicker .DateInput {
    width: 80%;
}
.DateInput {
    display: inline-flex;
}
.SingleDatePickerInput {
    background-color: #f1f2f2;
    border-radius: 7px;
    padding: 0.305rem 1rem 0.625rem;
    width: 100%;
}

#createEmployeeRoleSelect div[class$="-control"] {
    background-color: #f1f2f2;
    height: 55px;
}