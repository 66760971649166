.flag {
  display: inline-block;
  vertical-align: middle;
  line-height: 1em;
  margin-top: -0.125em;

  svg {
    display: inline-block;
    vertical-align: top;
    width: 1.33em;
    height: 1em;
  }
}
