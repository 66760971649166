$grid-columns: 12;
$grid-gutter-width: 24px;
$transition-fade: opacity 100ms linear !default;

//$grid-row-columns: 6 !default;
@import 'vars';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/transitions';

//
// Dropdown
//

$dropdown-min-width: 12rem;
$dropdown-border-width: 0;
$dropdown-box-shadow: $elevate-1;
$dropdown-border-radius: $border-radius-small;
$dropdown-spacer: 0;
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 1rem;
$dropdown-item-padding-x: 1rem;
$dropdown-color: $color-black;
$dropdown-font-size: font-size-fn(14px);
$dropdown-link-color: $color-black;
$dropdown-link-hover-color: $color-violet-hover;
$dropdown-link-hover-bg: $color-black95;
$dropdown-link-active-color:$color-black;
$dropdown-link-active-bg: $color-black90;
$dropdown-link-disabled-color: $color-black60;

@import '~bootstrap/scss/dropdown';

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
  line-height: line-height-fn(14px);
}
.dropdown-item {
  cursor: pointer;

  & + & {
    border-top: 1px solid $color-black90;
  }
  &.color-danger:hover,
  &.color-danger:focus,
  &.color-danger:active {
    color: $color-lightviolet;
  }
}
.dropdown__regular-toggle {
  padding: 1rem 3rem 1rem 1rem;
  background: $color-black95;
  border-radius: $border-radius-large;
  cursor: pointer;

  &-arrow {
    color: $color-black60;
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -0.5rem;
    transition: all 300ms $ease-in-out;
  }
}
.dropdown.show .dropdown__regular-toggle-arrow {
  transform: rotate(180deg);
}

//
// Popover
//

$popover-font-size: font-size-fn(16px);
// $popover-max-width:                 276px !default;
$popover-border-width: 0;
$popover-border-radius: $border-radius-small;
$popover-box-shadow: $elevate-1;

$popover-body-padding-y: 1rem;
$popover-body-padding-x: 1rem;

// $popover-arrow-width:               1rem !default;
// $popover-arrow-height:              .5rem !default;
// $popover-arrow-color:               $popover-bg !default;
// $popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;

@import '~bootstrap/scss/popover';

.popover {
  box-shadow: $elevate-1;
}

//
// Modal
//

// Padding applied to the modal body
$modal-inner-padding: 2.5rem;
// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: .5rem;
$modal-dialog-margin: .5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;
$modal-content-border-width: 0;
$modal-content-border-radius: $border-radius-large;
// $modal-backdrop-bg: $color-violet-hover;
// $modal-backdrop-opacity: .8;
$modal-footer-border-width: 0;
// $modal-header-padding-y:            1rem !default;
// $modal-header-padding-x:            1rem !default;
$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 480px;
$modal-sm: 300px !default;

@import '~bootstrap/scss/modal';
.modal-footer {
  justify-content: flex-start;
  padding-top: 0;
}
.modal-body {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@include media-breakpoint-down(sm) {
  .modal-body {
    padding: 2rem;
  }
  .modal-footer {
    padding: 0 2rem 2rem;
  }
}
.modal-backdrop {
  &:before {
    content: '×';
    position: absolute;
    top: 40px;
    right: 40px;
    color: #fff;
    opacity: 0.5;
    font-size: 46px;
    line-height: 24px;
  }

  @include media-breakpoint-down(sm) {
    &:before {
      top: 1rem;
      right: 1rem;
    }
  }
}
