@import '../../styles/vars';
@import '../../styles/mixins/type';

.selectbox {
  position: relative;
  display: block;

  > select {
    appearance: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
  > select::-ms-expand {
    display: none;
  }

  &__toggle {
    font-size: 16px;
    color: $color-black;
    display: block;
    position: relative;
    margin: 0;
    padding: 1.25rem 3rem 1.25rem 1rem;
    // padding: 1.875rem 1rem 0.625rem;
    background: $color-black95;
    border: 0;
    border-radius: $border-radius;

    &-arrow {
      color: $color-black60;
      font-size: 16px;
      line-height: 1;
      position: absolute;
      right: 1rem;
      top: 50%;
      margin-top: -0.5rem;
      transition: all 300ms $ease-in-out;
    }
  }

  &_empty &__toggle {
    color: $color-black64;
  }
}
.selectbox.show .dropdown__regular-toggle-arrow {
  transform: rotate(180deg);
}
