.face:hover {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.shake {
  animation: shake 820ms cubic-bezier(.36,.07,.19,.97) both;
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.page-transition {
  &.fade-exit {
    position: absolute;
    width: 100%;
    top: 0;
    transform: translateX(0);
  }
  &.fade-exit.fade-exit-active {
    opacity: 0;
    transform: translateX(-15%);
    transition: opacity 250ms, transform 250ms;
  }
  &.fade-enter {
    position: relative;
    opacity: 0;
    transform: translateX(15%);
    z-index: 1;
  }
  &.fade-enter.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 250ms, transform 250ms;
  }
}

.animation-slide-left,
.animation-slide-right {
  &.exit {
    position: relative;
    transform: translateX(0);
  }
  &.exit.exit-active {
    opacity: 0;
    transform: translateX(-15%);
    transition: opacity 150ms, transform 250ms;
  }
  &.enter {
    position: relative;
    opacity: 0;
    transform: translateX(15%);
    z-index: 1;
  }
  &.enter.enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 150ms, transform 250ms;
  }
}
.animation-slide-right {
  &.exit {
    transform: translateX(0);
  }
  &.exit.exit-active {
    transform: translateX(15%);
  }
  &.enter {
    transform: translateX(-15%);
  }
  &.enter.enter-active {
    transform: translateX(0);
  }
}
