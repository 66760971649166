@import "../../styles/vars";
@import "../../styles/funcs";
@import "../../styles/mixins/type";
@import "../../styles/mixins/breakpoints";

.input-file {
  position: relative;
  overflow: hidden;
  transition: color 180ms ease;

  &__input {
    position: absolute;
    overflow: hidden;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    z-index: -1;
  }
  &__box {
    font-size: 16px;
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 0 24px;
    border: 1px solid $color-black90;
    border-radius: $border-radius;
    cursor: pointer;
    transition: border 180ms ease;
  }
  &__icon {
    font-size: 22px;
    width: 1.5rem;
    margin-right: 16px;
  }
  &__label {
    font-size: 13px;
    flex-grow: 1;
  }
  &__status-line {
    font-size: 12px;
    color: $color-black60;
    display: block;
  }
  &__button {
    position: relative;
    color: $color-violet-hover;

    // Sub-elements
    &-loading,
    &-done,
    &-error {
      font-size: 22px;
      line-height: 1.6rem;
    }
    &-error {
      font-size: 18px;
    }
    &-upload {
      font-size: 13px;
      line-height: 1.6rem;
    }
    &-loading,
    &-done,
    &-error,
    &-upload {
      right: 0;
      transition: opacity 200ms $ease-in;
    }

    // Statuses
    &_status_upload &-loading { position: absolute; opacity: 0; }
    &_status_upload &-done { position: absolute; opacity: 0; }
    &_status_upload &-error { position: absolute; opacity: 0; }
    &_status_upload &-upload { position: relative; opacity: 1; }

    &_status_loading &-loading { position: relative; opacity: 1; }
    &_status_loading &-done { position: absolute; opacity: 0; }
    &_status_loading &-error { position: absolute; opacity: 0; }
    &_status_loading &-upload { position: absolute; opacity: 0; }

    &_status_done &-loading { position: absolute; opacity: 0; }
    &_status_done &-done { position: relative; opacity: 1; }
    &_status_done &-error { position: absolute; opacity: 0; }
    &_status_done &-upload { position: absolute; opacity: 0; }

    &_status_error &-loading { position: absolute; opacity: 0; }
    &_status_error &-done { position: absolute; opacity: 0; }
    &_status_error &-error { position: relative; opacity: 1; }
    &_status_error &-upload { position: absolute; opacity: 0; }
  }

  //
  // Hover
  //
  &__input:focus + &__box,
  &__box:hover {
    border-color: shade($color-black90, 10%);
  }

  //
  // Disabled
  //
  &_disabled {
    color: $color-black60;
    pointer-events: none;
  }

  //
  // Dragging
  //
  &_dragging &__box {
    border-style: dashed;
  }

  //
  // Style: White
  //
  $b: &;
  &_type_white {
    #{$b}__box {
      font-size: 13px;
      border-color: rgba(#fff, 0.5);
    }
    #{$b}__button {
      color: rgba(#fff, 0.9);
    }
    #{$b}__button-upload {
      color: rgba(#fff, 0.6);
    }
    & #{$b}__input:focus + #{$b}__box,
    & #{$b}__box:hover {
      border-color: rgba(#fff, 0.75);

      #{$b}__button-upload {
        color: rgba(#fff, 0.75);
      }
    }
  }
  &_type_white#{&}_disabled {
    color: rgba(#fff, 0.5);
  }

  &_type_link {
    #{$b}__box {
      font-size: 13px;
      flex-direction: row-reverse;
      justify-content: center;
      height: 2.5rem;
      border: none;
    }
    #{$b}__label {
      font-size: 13px;
      color: $color-violet-hover;
      flex-grow: 0;
      position: relative;
      transition: padding-left 180ms ease-in;
      will-change: padding;
    }
    #{$b}__button-upload {
      display: none;
    }
    &#{$b}_status_loading #{$b}__label,
    &#{$b}_status_done #{$b}__label,
    &#{$b}_status_error #{$b}__label {
      padding-left: 0.75rem;
    }
  }

  @include media-breakpoint-up(xxl) {
    &__label {
      font-size: 16px;
    }
  }
}
