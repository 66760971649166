@import "../../styles/vars";
@import "../../styles/mixins/type";

.label-value {
  &__label {
    color: $color-black60;
    font-size: 16px;
    display: flex;
  }
  &__value {
    word-break: break-all;
    display: flex;
  }

  & + & {
    margin-top: 2rem;
  }
}
