@import "../../styles/vars";
@import "../../styles/mixins/breakpoints";

.auth-paper {
  flex-grow: 1;
  margin: 0 auto;
  padding: 2rem;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: $elevate-1;

  &_size_tiny {
    max-width: 432px;
  }
  &_size_small {
    max-width: 480px;
  }
  &_size_medium {
    max-width: 880px;
  }
  &_size_stretch {
    max-width: none;
  }

  &_pad_0 {
    padding: 0;
  }


  &_columns {
    padding: 1.5rem 2rem;

    & > .row {
      margin-left: -2rem;
      margin-right: -2rem;
    }
    & > .row > .col {
      padding-left: 2rem;
      padding-right: 2rem;
      border-left: 1px solid $color-black90;

      &:first-child {
        border: 0;
      }
    }
  }

  &_picture {
    padding: 0;
    overflow: hidden;

    & > .row {
      margin-left: 0;
      margin-right: 0;
    }
    & > .row > .col {
      padding: 2.5rem 2rem;
    }
  }

  &.add-payment-card > .row {
    flex-direction: column-reverse;
  }


  @include media-breakpoint-up(md) {
    & {
      padding: 2.5rem;
    }
    &.add-payment-card > .row {
      flex-direction: row;
    }
    &_columns {
      padding: 32px 40px;
      & > .row {
        margin-left: -40px;
        margin-right: -40px;
      }
      & > .row > .col {
        padding: 8px 40px;
      }
    }
    &_picture {
      padding: 0;
      & > .row > .col {
        padding: 48px 40px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .layout__container & {
      box-shadow: none;
    }
  }
}
