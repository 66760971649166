.checkoutListStatusPicker {
    max-width: 100px;
    display: inline;
    border: none;
    background-color: #f1f2f2;
    color: #9d9f9f;
}

.statusPickerContainer {
    display:inline-block;
    width: 200px;
    * {
       margin-top: 0;
       margin-bottom: 0;
       background-color: #f1f2f2;
       
    }
}

#react-select-2-option-0,
#react-select-2-option-1,
#react-select-2-option-2,
#react-select-2-option-3,
#react-select-2-option-4,
#react-select-2-option-5,
#react-select-2-option-6,
#react-select-2-option-7,
#react-select-2-option-8,
#react-select-2-option-9 {
    text-align: initial;
}

.menu-item {
    height: 100%;
    width: 100%;
}

div[class$="-ValueContainer"] {
    padding: 4px 8px !important;
}