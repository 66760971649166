@import '../../styles/vars';
@import "../../styles/mixins/breakpoints";
@import "../../styles/mixins/type";



$topbar__height: 72px !default;

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $topbar__height;
  padding: 0 40px;
  background: #fff;
  border-bottom: 1px solid $color-black90;
  z-index: 1000;

  &__logo {

    svg {
      height: 32px;
      vertical-align: middle;
    }
  }

  &__lang-selector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__lang-selector {
    margin-right: 1.0em;
  }

  &__lang-selector-ar {
    margin-left: 1.0em;
  }

  &__auth-option {
    a {
      margin-left: .5em;
    }
  }

  &__auth-option-ar {
    a {
      margin-right: .5em;
    }
  }

  &__skip {
    margin-left: 1.5rem;

    a {
      color: $color-violet-hover;
      transition: color 180ms ease;
    }

    .icon {
      margin-left: 0.5em;
    }
  }

  &__skip-ar {
    margin-right: 1.5rem;

    a {
      color: $color-violet-hover;
      transition: color 180ms ease;
    }

    .icon {
      margin-right: 0.5em;
    }
  }

  @include media-breakpoint-up(md) {
    & {
      padding: 0 40px;
    }
  }
}
