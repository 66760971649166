
@import "../../styles/vars";
@import "../../styles/mixins/breakpoints";

.table-view {
  position: relative;
  min-height: 3rem;
  overflow-x: auto;

  &__loading {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(#fff, 0.8);
  }

  @include media-breakpoint-down(sm) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
