@import "../../styles/vars";
@import "../../styles/mixins/type";
@import '../../styles/mixins/breakpoints';

.action-tile {
  color: $color-black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // min-height: 10.5rem;
  padding: 1.5rem;
  background: #fff;
  box-shadow: $elevate-1;
  border-radius: $border-radius-large;

  %__icon {
    font-size: 22px;
    margin-bottom: 1rem;
  }

  &__icon-en {
    @extend %__icon;
  }

  &__icon-ar {
    @extend %__icon;
    float: right;
  }

  &__title {
    display: flex;
    line-height: 1.2;
    margin: 0;
  }
  %__description {
    font-size: 13px;
    color: $color-black60;
    margin: 0;
  }

  &__description-en {
    @extend %__description;
    text-align: left;
  }

  &__description-ar {
    @extend %__description;
    text-align: right;
  }

  &_actionable {
    cursor: pointer;
    transition: all 300ms $ease-in-out;

    &:hover {
      box-shadow: $elevate-6;
      transform: translate3d(0, -2px, 0);
    }
    &:active {
      box-shadow: $elevate-1;
      transform: translate3d(0, 0, 0);
      transition-duration: 100ms;
    }
    &:focus {
      outline: none;
    }
  }

  @include media-breakpoint-up(md) {
    .action-tile {
      min-height: 10.5rem;
    }
    &__title {
      display: flex;
      margin-bottom: 0.25rem;
    }
    &__description {
      display: flex;
    }
  }
}
