@import "../../styles/vars";
@import "../../styles/mixins/type";

.badge {
  font-size: 11px;
  line-height: 1.727;
  color: $color-black;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: inline-block;
  padding: 2px 0.5rem;
  border-radius: $border-radius-small;
  background: #E0E0E0;

  &_type_success {
    color: $color-green;
    background: #E5EFEF;
  }
  &_type_fail {
    color: $color-red;
    background: #FFE5E4;
  }
  &_type_highlight {
    color: $color-black;
    background: #FAE160;
  }

  &_size_large {
    font-size: 12px;
  }
}
