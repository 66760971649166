.otp-form {
  margin: 56px 0 0;

  .form__row_submit {
    margin-top: 68px;
  }
  &_error {
    border: 2px solid red;
  }
}
