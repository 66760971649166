@import "../../styles/vars";
@import "../../styles/mixins/type";

.quick-stat {
  padding: 1.5rem 2rem;
  background: #fff;
  border-radius: $border-radius-large;
  box-shadow: $elevate-1;

  &__header {
    margin-bottom: 2rem;
  }
  &__title {
    display: flex;
    font-size: 18px;
    margin: 0;
  }
  &__subtitle {
    display: flex;
    font-size: 13px;
    color: $color-black40;
    margin: 0 0 0.5rem;
  }
  &__data {
    display: flex;
    font-size: 22px;
    color: $color-black;
    font-weight: bold;
  }
}
