@import "../../styles/vars";
@import "../../styles/funcs";
@import "../../styles/mixins/type";
@import "../../styles/mixins/breakpoints";


.date-picker {
  direction: ltr;
}
.DateInput {
  background: none;
  text-align: center;
}
.DateInput_input {
  font-size: 16px;
  background: none;
}
.DateRangePicker {
  background: $color-black95;
  border-radius: $border-radius;
  will-change: border-color, background-color;
  transition: border-color 100ms, background-color 100ms;
}
.DateRangePickerInput {
  background: none;
}
.DateInput_input__focused {
  border-bottom-color: $color-violet-hover;
}
.DateRangePickerInput_calendarIcon {
  color: $color-black60;
  margin: 0 0 0 0.25rem;
}

// Days coloring
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  color: $color-violet-hover;
  background: #6542BE08;
  border-color: tint($color-violet-hover, 75);
}
.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  color: $color-black40;
  background: #6542BE08;
  border-color: tint($color-violet-hover, 75);
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $color-violet-hover;
  border-color: $color-violet-hover;
}

// .CalendarDay__today {
//   background: $color-black98;
// }

// Month
.CalendarMonth_caption {
  font-size: 16px;
}

// Arrows
.DayPickerNavigation_button__horizontalDefault {
  padding: 0.5rem;
  margin-top: -4px;
  border-radius: 100%;
}


@include media-breakpoint-down(xs) {
  .DateRangePickerInput_calendarIcon {
    display: none;
  }
  .DateInput {
    font-size: 13px;
    width: calc(50% - 10px);
  }
}
