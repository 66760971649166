@import "../../styles/vars";
@import "../../styles/mixins/type";
@import '../../styles/mixins/breakpoints';

.reports {

  &__container {
    margin: 0 auto;
  }

  &__filters {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__pagination {
    margin: 2rem 0 1.5rem;
  }
  &__sub {
    font-size: 12px;
    color: $color-black40;
  }

  @include media-breakpoint-down(sm) {
    &__filters {
      flex-direction: column;
      margin-bottom: 1rem;
      text-align: center;

      & > * {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.column-status-en {
  text-align: left;
  display: grid;
}

.column-status-ar {
  text-align: right;
  display: grid;
}

.column-button-en {
  text-align: left;
}

.column-button-ar {
  text-align: center;
}


.column-title-en {
  text-align: left;
}

.column-title-ar {
  text-align: right;
}

.column-render-en {
  text-align: left;
}

.column-render-ar {
  text-align: right;
  direction: ltr;
}

.line-height {
  line-height: 1.727;
}