@import '../../styles/vars';
@import '../../styles/mixins/type';
@import '../../styles/funcs';

@mixin button-hover($color, $bgcolor) {
  &:hover,
  &:focus {
    color: $color;
    &:before {
      background-color: $bgcolor;
      border-color: $bgcolor;
    }
  }
}
@mixin button-active($color, $bgcolor) {
  &:active,
  &.active {
    color: $color;
    &:before {
      background-color: $bgcolor;
      border-color: $bgcolor;
    }
  }
}
@mixin button-disabled($color, $bgcolor) {
  &_disabled,
  &.button_disabled,
  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $color;
      &:before {
        background-color: $bgcolor;
        border-color: $bgcolor;
      }
    }
  }
}

.button {
  position: relative;
  display: inline-block;
  font-weight: $button__font-weight;
  white-space: nowrap;
  text-align: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 180ms $ease-in;
  user-select: none;
  touch-action: manipulation;

  &,
  &:active,
  &:focus {
    outline: 0;
  }
  &:not([disabled]):hover {
    text-decoration: none;
  }
  &:not([disabled]):active {
    outline: 0;
    box-shadow: none;
  }
  &.button_disabled,
  &[disabled] {
    cursor: not-allowed;
    > * {
      pointer-events: none;
    }
  }

  // Background
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    will-change: background, border, box-shadow;
    transition:
      background 180ms $ease-in,
      border  180ms $ease-in,
      box-shadow  180ms $ease-in;
  }
  &:not([disabled]):active {
    transform: translateY(1px);
  }

  // http://stackoverflow.com/a/21281554/3040605
  span {
    position: relative;
    transition: padding-left 180ms ease-in;
    will-change: padding;
  }

  // Icon
  .icon-en + span,
  span + .icon-en {
    margin-left: 0.5rem;
  }
  .icon-en {
    height: 1em;
    transition: margin-left 300ms $ease-in-out;
    pointer-events: none;
  }

  .icon-ar + span,
  span + .icon-ar {
    margin-right: 0.5rem;
  }
  .icon-ar {
    height: 1em;
    transition: margin-right 300ms $ease-in-out;
    pointer-events: none;
  }

  //
  // Type modifier
  //
  &_type_default {
    color: $color-black;

    &:before {
      background-color: $color-black90;
      border-color: $color-black90;
    }

    @include button-hover($color-black, shade($color-black90, 5%));
    @include button-active($color-black, shade($color-black90, 7%));
    @include button-disabled($color-black60, $color-black95);
  }
  &_type_primary {
    color: $color-white;

    &:before {
      background-color: $color-violet;
      border-color: $color-violet;
    }

    @include button-hover($color-white, $color-violet-hover);
    @include button-active($color-white, shade($color-violet-hover, 5%));
    @include button-disabled($color-white, rgba($color-violet, 0.5));
  }
  &_type_secondary {
    color: $color-white;

    &:before {
      background-color: $color-violet-hover;
      border-color: $color-violet-hover;
    }

    @include button-hover($color-white, shade($color-violet-hover, 5%));
    @include button-active($color-white, shade($color-violet-hover, 7%));
    @include button-disabled($color-white, rgba($color-violet-hover, 0.75));
  }
  &_type_link {
    color: $link__color;

    &:before {
      background-color: transparent;
    }

    @include button-hover($link__color, transparent);
    @include button-active($link__color, transparent);
    @include button-disabled($color-black40, transparent);
  }
  &_type_transparent {
    color: $color-black;

    &:before {
      background-color: transparent;
      border: 0;
    }

    @include button-hover($color-black, transparent);
    @include button-active($color-black, $color-black95);
    @include button-disabled($color-black60, transparent);
  }

  //
  // Size
  //
  &_size_default {
    font-size: 16px;
    padding: .75rem 1.5rem;

    &,
    &:before {
      border-radius: $border-radius;
    }
  }
  &_size_small {
    font-size: 13px;
    padding: 0.5625rem 1rem;

    &,
    &:before {
      border-radius: $border-radius;
    }
  }
  &_size_large {
    font-size: 16px;
    padding: 1.125rem 2.5rem;

    &,
    &:before {
      border-radius: $border-radius-large;
    }

    .icon-en + span,
    span + .icon-en {
      margin-left: 1rem;
    }

    .icon-ar + span,
    span + .icon-ar {
      margin-right: 1rem;
    }
  }

  //
  // Shape
  //
  &_shape_square {
    $size:  line-height-fn(16px) + 0.75em * 2;
    height: $size;
    width: $size;
    padding: 0;

    &.button_size_small {
      $size: 2.5rem;
      height: $size;
      width: $size;
      line-height: $size - 0.01rem;
    }
    &.button_size_large {
      $size:  3.5rem;
      height: $size;
      width: $size;
      line-height: $size - 0.01rem;
    }
  }
  &_shape_circle {
    $size:  line-height-fn(16px) + 0.75em * 2;
    padding-left: 0;
    padding-right: 0;
    height: $size;
    width: $size;
    border-radius: 50%;

    &:before {
      border-radius: 50%;
    }

    &.button_size_small {
      $size:  line-height-fn(14px) * (14px/16px) + (0.5625rem * 2);
      height: $size;
      width: $size;
    }
    &.button_size_large {
      $size:  line-height-fn(16px) * (14px/16px) + 1.125rem * 2;
      height: $size;
      width: $size;
    }
  }

  //
  // Ghost
  //
  &_ghost {
    &:before {
      border-width: 1px;
      border-style: solid;
    }

    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      &:before {
        background-color: transparent;
      }
    }
    &_disabled,
    &.disabled,
    &[disabled] {
      &,
      &:hover,
      &:focus,
      &:active,
      &.active {
        &:before {
          background-color: transparent;
        }
      }
    }
    &.button_type_primary {
      color: $color-violet;
    }
    &.button_type_secondary {
      color: $color-violet-hover;
    }
  }

  //
  // Block
  //
  &_block {
    width: 100%;
  }

  //
  // Loading
  //
  &_loading:not([disabled]) {
    pointer-events: none;

    &:before {
      opacity: .75;
    }
    > span {
      padding-left: 1em;
    }
    .icon-en {
      margin-left: -1em;
    }
    .icon-ar {
      margin-right: -1em;
    }
  }

  &_no-paddings {
    padding: 0;
  }
}

