@import "./vars";
@import "./mixins/type";
@import "./normalize";
@import "./custom";
@import "./animation";

@font-face {
  font-family: 'LexendDeca';
  src: local('LexendDeca'), url(../fonts/LexendDeca-Regular.ttf) format('truetype');
}

* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  overflow-x: hidden;
  font-family: 'LexendDeca' !important;
}
body {
  font: normal 1em/#{1.25} $font-primary;
  background: $color-background;
}

#root {
  display: flex;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

// Basic typography
::placeholder {
  color: $color-black60;
}

a,
.link {
  color: $color-violet-hover;
  text-decoration: none;
  cursor: pointer;
  padding: 7px 12px;
  &:hover {
    color: #411351;
    background-color: #6542BE08;
    text-decoration: none;
  }
}
p {
  margin: 0 0 1rem;
}

.nobr {
  white-space: nowrap;
}
.text_align_left {
  text-align: left;
}
.text_align_right {
  text-align: right;
}
.text_align_center {
  text-align: center;
}
.color_black40 {
  color: $color-black40;
}
.color_black60 {
  color: $color-black60;
}


.color-black {
  color: $color-black;
}
.color-black40 {
  color: $color-black40;
}
.color-black60 {
  color: $color-black60;
}
.color-link {
  color: $color-violet-hover;
}
.color-highlight {
  color: $color-yellow;
}
.color-danger {
  color: $color-red;
}

.min-height-100 {
  height: 100%;
}

.mb-16  {
  margin-bottom: 16px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}

