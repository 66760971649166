@import "../../styles/vars";
@import "../../styles/mixins/breakpoints";

.paper {
  position: relative;
  padding: 2.5rem;
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(#283C51, 0.06), 0 2px 6px 0 rgba(#283C51, 0.15);
  border-radius: 16px;

  &_padding_small {
    padding: 2rem 1.5rem;
  }

  @include media-breakpoint-down(lg) {
    & {
      padding: 2rem;
    }
    &_padding_small {
      padding: 1.5rem;
    }
  }
  @include media-breakpoint-down(sm) {
    & {
      padding: 1.5rem;
      margin-bottom: 2rem;
    }
    &_mobile-full {
      margin: 0 -1.5rem;
      border-radius: 0;
      min-height: 400px;
    }
  }
}
