@import "../../styles/vars";
@import '../../styles/mixins/breakpoints';

.checkout {

  &__container {
    margin: 0 auto;
  }

  &__container-small {
    margin: 0 auto;
    max-width: 944px;
  }
}
