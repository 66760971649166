.rotate-180 {
  transform: rotate(180deg);
}

.icon-en, .icon-ar {
  vertical-align: middle;
  margin-top: -0.125em;

  svg {
    display: inline-block;
  }
}
.icon_type_loading {
  animation: loadingCircle 1s infinite linear;
}
@keyframes loadingCircle{
  100% {
    transform: rotate(360deg);
  }
}
