@import "../../styles/vars";
@import "../../styles/mixins/type";

.drafts-list {
  &__controls {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__date {
    font-size: 12px;
  }
  &__sub {
    font-size: 12px;
    color: $color-black40;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
  &__pagination {
    margin: 2rem 0 1.5rem;
  }
}

.column-status-en {
  text-align: left;
  display: grid;
}

.column-status-ar {
  text-align: right;
  display: grid;
}

.column-button-en {
  text-align: left;
}

.column-button-ar {
  text-align: right;
}


.column-title-en {
  text-align: left;
}

.column-title-ar {
  text-align: right;
}

.column-render-en {
  text-align: left;
}

.column-render-ar {
  text-align: right;
  direction: ltr;
}

.line-height {
  line-height: 1.727;
}