@import "../../styles/vars";
@import '../../styles/mixins/breakpoints';

.home {

  &__container {
    max-width: 944px;
    margin: 0 auto;
  }

  &__grid {
    align-items: stretch;
    margin-bottom: 1.5rem;

    .action-tile {
      height: 100%;
    }
  }

  &__quick-stats {
    & > [class*="col"] + [class*="col"] {
        margin-top: $grid-gutter;
    }
  }
  @include media-breakpoint-down(sm) {
    &__grid {
      margin: 0;

      & > .col-12,
      & > .col-8,
      & > .col-6 {
        margin-bottom: 1.5rem;
      }
    }
  }
}
