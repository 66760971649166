@import "../../styles/vars";
@import "../../styles/mixins/type";

.block-head {
  text-align: center;
  margin: 8px 0 40px;

  h1 {
    font-size: 28px;
    margin: 0 0 8px;
  }
  p {
    color: $color-black40;
  }
  strong {
    color: $color-black;
  }

  &_type_under {
    margin: 40px 1rem 0;

    p + p {
      margin-top: 2rem;
    }
  }
  &_type_normal {
    text-align: left;
  }
}
