@import "../../styles/vars";
@import "../../styles/mixins/type";
@import '../../styles/mixins/breakpoints';

.pagination {
  padding-top: 1rem;
  text-align: center;

  & > ul {
    padding: 0;
    margin: 0;
  }

  & li {
    display: inline-block;
  }
  & li.previous {
    margin-right: 2rem;
  }
  & li.next {
    margin-left: 2rem;
  }
  & li > a {
    color: $color-black;
    padding: 0.5rem;
    margin: 1px;
    display: inline-block;
    min-width: 2rem;
    border-radius: $border-radius;
    cursor: pointer;
  }
  & li.disabled > a {
    color: $color-black60;
  }
  & li.selected > a {
    background: $color-black95;
  }

  & li > a:focus {
    outline: none;
  }

  @include media-breakpoint-down(xs) {
    & li{
      display: none;
    }
    & li.selected,
    & .previous,
    & .next {
      display: inline-block;
    }
  }
}
