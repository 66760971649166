@import "../../styles/vars";
@import "../../styles/mixins/type";
@import "../../styles/mixins/breakpoints";


.radio-group_alignment_horizontal {
  .radio-group__button {
    &_checked {
      border-color: rgba(255, 77, 74, .2);
      background: rgba(255, 77, 74, .1);
    }
  }
}