@import "../../styles/vars";
@import "../../styles/mixins/type";

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0 0 0.5rem;
}
.h1 {
  font-size: 28px;
  font-weight: 700;
}
.h2 {
  font-size: 22px;
  font-weight: 700;
}
.h3 {
  font-size: 18px;
  font-weight: 500;
}
.h4 {
  font-size: 16px;
  font-weight: 500;
}
