@import "../../styles/vars";
@import "../../styles/mixins/type";

.profile-dropdown {
  cursor: pointer;
  color: $color-black40;
  padding: 0 8px;

  %__pic {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    background: $color-black90;
    border-radius: 16px;
  }

  &__pic-en {
    @extend %__pic;
    margin: -2px 8px 0 0;
  }

  &__pic-ar {
    @extend %__pic;
    margin: -2px 8px 0;
  }
  &__name {
    font-size: 16px;
  }

  &:hover {
    color: $color-black;
  }
}

.dropdown-item .icon-en {
  margin-right: 1rem;
}

.dropdown-item .icon-ar {
  margin-left: 1rem;
}

.profile-dropdown__menu.dropdown-menu {
  margin-top: 0.5rem;
  box-shadow: $elevate-4;  
}
