@import "../../styles/vars";
@import "../../styles/mixins/type";
@import "../../styles/mixins/breakpoints";

.settings {

  &__container {
    max-width: 944px;
    margin: 0 auto;
  }

  &__section {
    margin: 0 0 40px;
  }
  &__section-label {
    display: flex;
  }
  &__section_spacing_small {
    margin-bottom: 1.5rem;
  }
  &__section_spacing_large {
    margin-bottom: 64px;
  }

  &__api-key {
    font-size: 13px;
  }
  %__copy-to-clip {
    font-size: 16px;
    width: 2rem;
    height: 2rem;
  }

  &__copy-to-clip-en {
    @extend %__copy-to-clip;
    margin-left: 1rem;
  }

  &__copy-to-clip-ar {
    @extend %__copy-to-clip;
    margin-right: 1rem;
  }

  &__paragraph-en {
    text-align: left;
  }

  &__paragraph-ar {
    text-align: right;
  }

  &__address-paragraph-en {
    text-align: left;
    margin-bottom: 0px;
  }

  &__address-paragraph-ar {
    text-align: right;
    margin-bottom: 0px;
  }

  &__language-selector {
    margin-top: 0.5rem;
    width: 150px;
    float: left;
  }

  &__email-form-container {
    margin-top: 1.0rem;
  }

  &__email-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__clickable {
    cursor: pointer;
  }

  &__header-en {
    text-align: left;
  }

  &__header-ar {
    text-align: right;
  }
  

  @include media-breakpoint-down(md) {
    &__section-label {
      margin-bottom: 1rem;
      display: flex;
    }
  }
  @include media-breakpoint-down(sm) {
    &__section-label {
      font-weight: bold;
      display: flex;
    }
    &__api-key {
      // font-size: 13px;
      word-break: break-all;
    }
  }
}



.code-snippet {
  display: block;
  font-family: 'LexendDeca';
  white-space: pre;
  padding: 1rem;
  margin: 1em 0;
  background: $color-black90;
  border-radius: $border-radius;
  width: 100%;
  overflow-x: auto;
}
