@import "../../styles/vars";

.app-loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  & > div {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2rem;
    margin: 0 auto;
    font-size: 30px;
    line-height: 2rem;
    transform: translate3d(-50%, -50%, 0);
  }
}
