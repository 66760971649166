@import "../../styles/vars";
@import "../../styles/mixins/type";
@import '../../styles/mixins/breakpoints';

.bank-accounts-list-item {
  margin: 0;
  padding: 1rem 0;

  & + & {
    border-top: 1px solid $color-black90;
  }
  &:first-child {
    padding-top: 0;

    .bank-accounts-list-item__badge {
      margin-top: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-content: stretch;
  }
  &__account-en {
    color: $color-black40;
    flex-grow: 1;
    word-break: break-all;
    text-align: left;

    strong {
      font-weight: 500;
      color: $color-black;
    }
  }
  &__account-ar {
    color: $color-black40;
    flex-grow: 1;
    word-break: break-all;
    text-align: right;

    strong {
      font-weight: 500;
      color: $color-black;
    }
  }
  &__badge {
    margin-top: 0.5rem;
  }
  &__btn.show.dropdown .dropdown__toggle .button:before {
    background: $color-black95;
  }

  @include media-breakpoint-down(md) {
    &__content {
      flex-direction: column;
    }
    &__account-en {
      font-size: 13px;
      text-align: left;
    }
    &__account -ar{
      font-size: 13px;
      text-align: right;
    }
    &:first-child &__badge,
    &__badge {
      margin-top: 0.5rem;
    }
  }
}
