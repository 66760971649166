@import "../../styles/vars";
@import "../../styles/mixins/type";

.avatar {
  color: $color-black40;
  text-align: center;
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  background: $color-black90;
  border-radius: 50%;

  // Default
  font-size: 12px;
  width: 32px;
  height: 32px;
  line-height: 32px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__string {
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: 0 center;
    transform: translate3d(-50%, -48%, 0);
    letter-spacing: 0.05em;
  }

  &_size_large {
    font-size: 22px;
    width: 144px;
    height: 144px;
    line-height: 144px;

  }
  &_size_small {
    width: 16px;
    height: 16px;
    line-height: 16px;
  }
}
